<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" md="6" lg="4">
        <v-card height="100%" width="100%" class="pa-4">
          <v-card-title>Join Meeting</v-card-title>
          <v-divider class="my-4"></v-divider>
          <v-text-field
            v-model="code"
            label="Enter Meeting Code"
          ></v-text-field>
          <v-card-actions>
            <v-btn color="primary" width="100%" @click="redirectToMeeting"
              >Join</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    code: "",
  }),

  methods: {
    redirectToMeeting() {
      location.href = "https://live.shor.tn/" + this.code;
    },
  },
};
</script>

<style>
</style>